<template>
  <div class="flex">
    <img
      src="/images/appLogoWhiteMobile@2x.png"
      srcset="/images/appLogoWhiteMobile@2x.png"
      alt="Image of Gambling Regulatory Authority of Ireland"
      class="lg:hidden w-15 h-10"
    />

    <img
      src="/images/appLogoWhite@2x.png"
      srcset="/images/appLogoWhite@2x.png"
      alt="Image of Gambling Regulatory Authority of Ireland"
      class="lg:block hidden w-60 h-30 py-2"
    />
  </div>
</template>
