<script setup>
import { MagnifyingGlassIcon } from '@heroicons/vue/24/outline'
import InputLabel from '@/Shared/Components/Forms/InputLabel.vue'
import TextInput from '@/Shared/Components/Forms/TextInput.vue'
import { XCircleIcon } from '@heroicons/vue/20/solid'
import { ref, computed } from 'vue'
import SecondaryButton from '@/Shared/Components/Buttons/SecondaryButton.vue'

const search = ref('')

const searchLink = computed(() => {
  return `/search?q=${search.value}`
})
</script>

<template>
  <div class="z-40">
    <h1 class="text-3xl font-extrabold mb-5">Search</h1>
    <div class="flex md:flex-row flex-col">
      <div class="relative w-full cursor-default rounded-lg bg-white text-left sm:text-sm grow mt-[2px]">
        <InputLabel
          for="search"
          class="sr-only"
          value="Enter a search query"
        />
        <TextInput
          id="search"
          ref="searchInput"
          v-model="search"
          type="text"
          class="w-full border-[#505A5F] rounded-md pl-10 shadow-sm focus:border-2 focus:ring focus:ring-yellow-500 py-2"
          placeholder="Search..."
          autofocus
        />
        <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <MagnifyingGlassIcon
            class="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </div>
        <button
          type="button"
          class="absolute inset-y-0 right-0 flex items-center px-2 focus:outline-none focus:ring focus:ring-yellow-500"
          tabindex="0"
        >
          <XCircleIcon
            v-if="search.length > 0"
            class="h-5 w-5 text-black"
            aria-hidden="true"
            @click="search = ''"
          />
        </button>
      </div>
      <SecondaryButton
        class="px-14 justify-center md:ml-4 mt-6 md:mt-0 flex-none items-center cursor-pointer font-bold whitespace-nowrap motion-safe:transition border bg-white border-black text-black hover:bg-black hover:text-white focus:outline-none focus:border-black focus:border-2 focus:ring-2 focus:ring-yellow-500 disabled:bg-white disabled:text-black disabled:opacity-25"
        as="a"
        :href="searchLink"
      >
        Search
      </SecondaryButton>
    </div>
  </div>
</template>
