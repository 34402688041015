import '../Shared/bootstrap'

import * as Sentry from '@sentry/vue'

// import dayjs from 'dayjs'

import { createApp } from 'vue'

import AppLogo from '@/Shared/Components/AppLogo.vue'
import AppLink from '../Shared/Components/Links/AppLink.vue'
import AppHeader from '@/Shared/Layouts/AppHeader.vue'
import BetaBanner from '@/Shared/Components/BetaBanner.vue'
import ShowMoreList from '@/Shared/Components/ShowMoreList.vue'
import PublicLeftNavigation from '@/Public/Layouts/PublicLeftNavigation.vue'
import PublicRightNavigation from '@/Public/Layouts/PublicRightNavigation.vue'
import SearchPanel from '@/Public/Components/SearchPanel.vue'
import HelpUsImprove from '@/Public/Components/HelpUsImprove.vue'
import CookieBanner from '@/Public/Components/CookieBanner.vue'

const app = createApp({
  components: {
    AppLogo,
    AppLink,
    PublicLeftNavigation,
    PublicRightNavigation,
    AppHeader,
    ShowMoreList,
    BetaBanner,
    SearchPanel,
    HelpUsImprove,
    CookieBanner,
  },
})

if (import.meta.env.VITE_APP_ENV != 'local' && import.meta.env.VITE_APP_ENV != 'testing') {
  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_LARAVEL_DSN,
    environment: import.meta.env.VITE_APP_ENV,
  })
}

app.mount('#public-app')
