<script setup>
import { computed } from 'vue'
import { ArrowTopRightOnSquareIcon } from '@heroicons/vue/20/solid'

const props = defineProps({
  href: String,
  secondary: Boolean,
  visited: Boolean,
  target: String,
  external: Boolean,
  label: String,
  role: {
    type: String,
    default: 'a',
  },
})

const classes = computed(() => {
  if (props.secondary) {
    return `text-black underline decoration-1 underline-offset-2 hover:decoration-2 focus:outline-none focus:bg-yellow-500 focus:decoration-2${
      props.visited ? 'visited:text-purple-500' : ''
    }`
  }

  return `text-primary-link underline decoration-1 underline-offset-2 hover:decoration-2 focus:outline-none focus:bg-yellow-500 focus:decoration-2 ${
    props.visited ? 'visited:text-purple-500' : ''
  }`
})
</script>

<template>
  <a
    :role="props.role"
    :href="props.href"
    :class="classes"
    :target="target"
  >
    <slot v-if="$slots.default" />
    <span
      v-else
      class="flex items-center"
    >
      {{ label }}
      <ArrowTopRightOnSquareIcon
        v-if="external"
        class="h-5 w-5 ml-1"
        aria-hidden="true"
      />
    </span>
  </a>
</template>
