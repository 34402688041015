<script setup>
import { ref } from 'vue'
import AppLink from '@/Shared/Components/Links/AppLink.vue'
import {
  Popover,
  PopoverButton,
  PopoverGroup,
  PopoverPanel,
  TransitionRoot,
  Dialog,
  TransitionChild,
  DialogPanel,
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from '@headlessui/vue'
import { ChevronRightIcon } from '@heroicons/vue/20/solid'
import { ChevronLeftIcon } from '@heroicons/vue/20/solid'
import { ChevronDownIcon } from '@heroicons/vue/20/solid'
import { XMarkIcon } from '@heroicons/vue/24/outline'
import { Bars3Icon } from '@heroicons/vue/24/solid'
import PrimaryButton from '../../Shared/Components/Buttons/PrimaryButton.vue'
import SecondaryButton from '../../Shared/Components/Buttons/SecondaryButton.vue'
import SearchButton from '@/Public/Components/Buttons/SearchButton.vue'
import SearchPanel from '@/Public/Components/SearchPanel.vue'

const props = defineProps({
  items: Array,
})

let searchPanelOpen = ref(false)
let openMobileNavigation = ref(false)
let hoveredPopover = ref(null)

const goTo = (url) => {
  url ? (window.location.href = url) : ''
}

const openGrandparent = ref(0)
const openChild = ref(0)
</script>

<template>
  <TransitionRoot
    as="template"
    :show="openMobileNavigation"
  >
    <Dialog
      as="div"
      class="relative z-40 lg:hidden"
      @close="openMobileNavigation = false"
    >
      <div class="fixed inset-0 z-40 flex">
        <TransitionChild
          as="template"
          enter="transition ease-in-out duration-300 transform"
          enter-from="-translate-x-full"
          enter-to="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leave-from="translate-x-0"
          leave-to="-translate-x-full"
        >
          <DialogPanel class="flex w-full flex-col bg-primary-green-dark pb-12 shadow-xl">
            <div class="flex px-4 pb-2 pt-4 justify-between bg-primary-green">
              <div class="flex">
                <img
                  src="/images/appLogoWhiteMobile@2x.png"
                  class="h-10 w-15"
                  srcset="/images/appLogoWhiteMobile@2x.png"
                  alt="Image of Gambling Regulatory Authority of Ireland"
                />
              </div>
              <div class="flex">
                <SecondaryButton
                  class="bg-white mr-4"
                  icon-button
                  @click="openMobileNavigation = false"
                >
                  Menu
                  <XMarkIcon
                    class="h-6 w-6 ml-2"
                    aria-hidden="true"
                  />
                </SecondaryButton>

                <SearchButton
                  :is-search-panel-open="searchPanelOpen"
                  @click="searchPanelOpen = !searchPanelOpen"
                />
              </div>
            </div>

            <!-- Links -->

            <div class="px-4">
              <SearchPanel
                v-if="searchPanelOpen"
                class="mt-4"
              />
            </div>

            <Disclosure
              as="div"
              v-for="item in items"
              :key="item.title"
              v-slot="{ open }"
            >
              <DisclosureButton
                v-if="(openGrandparent == 0 || openGrandparent == item.id) && openChild == 0"
                :class="!open ? 'justify-between' : ''"
                class="flex w-full px-3 py-5 text-left font-medium text-white focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75"
                @click="() => (open ? (openGrandparent = 0) : (openGrandparent = item.id))"
              >
                <ChevronLeftIcon
                  v-if="open"
                  class="h-7 w-7 text-white mr-4"
                />

                <span class="text-left mt-0.5">{{ item.title }}</span>

                <ChevronRightIcon
                  v-if="!open"
                  class="h-7 w-7 text-white"
                />
              </DisclosureButton>
              <DisclosurePanel class="px-3 text-gray-500">
                <Disclosure
                  as="div"
                  v-for="childItem in item.children"
                  :key="childItem.title"
                  v-slot="{ open }"
                >
                  <DisclosureButton
                    v-if="openChild == 0 || openChild == childItem.id"
                    @click="() => (open ? (openChild = 0) : (openChild = childItem.id))"
                    :class="!open ? 'justify-between' : ''"
                    class="flex w-full py-5 text-left font-medium text-white focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75"
                  >
                    <ChevronLeftIcon
                      v-if="open"
                      class="h-7 w-7 text-white mr-4"
                    />
                    <span class="mt-0.5">{{ childItem.title }}</span>

                    <ChevronRightIcon
                      v-if="!open"
                      class="h-7 w-7 text-white"
                    />
                  </DisclosureButton>
                  <DisclosurePanel class="pb-6 text-gray-500">
                    <ul
                      v-for="grandChildItem in childItem.children"
                      :key="grandChildItem.title"
                      class=""
                    >
                      <li class="py-2">
                        <AppLink
                          :href="grandChildItem.url"
                          class="text-white"
                        >
                          {{ grandChildItem.title }}
                        </AppLink>
                      </li>
                    </ul>
                  </DisclosurePanel>
                </Disclosure>
              </DisclosurePanel>
              <hr class="border-secondary-gray" />
            </Disclosure>

            <div class="pl-3 mt-10">
              <div
                class="mt-5"
                v-if="openGrandparent != 0"
              ></div>
              <h3 class="font-bold text-nav-link-header">Do you need help?</h3>
              <p class="mt-4 text-white">
                If you or someone close to you is struggling with gambling or feeling overwhelmed there is help
                available.
              </p>
              <p class="mt-4 text-white">
                For more information on who you can talk to or organisations that can provide assistance go to our
              </p>
              <AppLink
                href="/gambling-safety/understand-your-gambling/get-help"
                secondary
                class="text-white focus:text-black"
              >
                Get Help Section.
              </AppLink>
            </div>
          </DialogPanel>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>

  <div
    class="cursor-pointer text-white p-3 float-end lg:hidden"
    @click="openMobileNavigation = true"
  >
    <PrimaryButton
      class="bg-primary-button"
      icon-button
    >
      Menu
      <Bars3Icon class="w-5 h-5 ml-2" />
    </PrimaryButton>
  </div>

  <div>
    <div class="flex justify-around max-lg:hidden divide-white/30 divide-x-2 border-l-2 border-r-2 border-white/30">
      <div
        v-for="item in items"
        :key="item.title"
        class="flex flex-auto"
      >
        <div
          class="flex items-center w-full justify-around hover:bg-primary-green-light hover:border-b-primary-button hover:border-b-4 border-b-transparent border-b-4 focus:bg-primary-green-dark outline-none"
          @mouseenter="hoveredPopover = item.title"
          @mouseleave="hoveredPopover = null"
        >
          <button
            class="flex items-center w-full justify-around focus:bg-primary-green-dark outline-none"
            @click="goTo(item.url)"
            @keyup.enter="goTo(item.url)"
            @focus="hoveredPopover = item.title"
            :class="hoveredPopover === item.title ? 'bg-primary-green-dark  border-b-primary-button' : ''"
          >
            <div
              :class="hoveredPopover === item.title ? '' : 'text-opacity-90'"
              class="py-2 group flex items-center text-base font-medium text-white"
            >
              <span>
                {{ item.title }}
              </span>
              <ChevronDownIcon
                v-if="item.children.length > 0"
                :class="hoveredPopover === item.title ? '' : 'text-opacity-70'"
                class="h-8 w-8 mb-0.5 text-white"
                aria-hidden="true"
              />

              <span class="sr-only">{{ hoveredPopover === item.title ? 'Open' : 'Close' }} {{ item.title }}</span>
            </div>
          </button>

          <transition
            enter-active-class="transition ease-out duration-200"
            enter-from-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="transition ease-in duration-150 delay-[120ms]"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
          >
            <div
              v-if="hoveredPopover === item.title"
              class="bg-primary-green-dark absolute inset-x-0 shadow-md top-full z-20 xl:left-1/2 xl:transform xl:-translate-x-1/2 xl:w-[100vw] min-h-[350px]"
            >
              <div class="relative">
                <div class="mx-auto max-w-7xl px-8">
                  <div class="flex flex-row py-4">
                    <div class="basis-3/4 grid gap-x-8 sm:grid-cols-3">
                      <div
                        v-for="childItem in item.children"
                        :key="childItem.title"
                        class="py-2"
                      >
                        <h3 class="font-bold text-nav-link-header pb-3">
                          {{ childItem.title }}
                        </h3>
                        <ul
                          v-for="grandChildItem in childItem.children"
                          :key="grandChildItem.title"
                          class=""
                        >
                          <li class="mt-2">
                            <AppLink
                              role="button"
                              :href="grandChildItem.url"
                              class="text-white focus:text-black"
                            >
                              {{ grandChildItem.title }}
                            </AppLink>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="basis-1/4 border-l border-l-gray-300 py-2 px-4 min-h-[320px] text-nav-link-header">
                      <h3 class="font-bold">Do you need help?</h3>
                      <p class="mt-4 text-white">
                        If you or someone close to you is struggling with gambling or feeling overwhelmed there is help
                        available.
                      </p>
                      <p class="mt-4 text-white">
                        For more information on who you can talk to or organisations that can provide assistance go to
                        our
                      </p>
                      <AppLink
                        href="/gambling-safety/understand-your-gambling/get-help"
                        secondary
                        class="text-white focus:text-black"
                      >
                        Get Help Section.
                      </AppLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>
