<script setup>
import { nextTick, ref } from 'vue'
import PrimaryButton from '@/Shared/Components/Buttons/PrimaryButton.vue'
import AppLink from '@/Shared/Components/Links/AppLink.vue'
import storage from '@/Utils/storage.js'

defineProps({
  /**
   * The cookies url page
   */
  url: {
    type: String,
    default: '/cookie-policy',
  },
})

const confirmationMessageElement = ref(null)
const alreadyAcknowledged = ref(
  Boolean(storage.get({ type: storage.types.cookie, name: 'cookie-notification-acknowledged' }))
)
const recentlyAcknowledged = ref(false)

const onAcceptAll = () => {
  setAllOptionalPermissions(true)
  focusConfirmation()
}

const onAcceptEssential = () => {
  setAllOptionalPermissions(false)
  focusConfirmation()
}

const focusConfirmation = () => {
  nextTick(() => {
    if (confirmationMessageElement.value) {
      confirmationMessageElement.value.focus()
    }
  })
}

const setAllOptionalPermissions = (allow) => {
  const cookiePermissions = JSON.parse(JSON.stringify(storage.categories))
  for (const key in cookiePermissions) {
    if (key === storage.categories.necessary) {
      cookiePermissions[key] = true
    } else {
      cookiePermissions[key] = allow
    }
  }

  storage.setCookie(storage.categories.necessary, 'cookiePermissions', JSON.stringify(cookiePermissions), 365)
  storage.setCookie(storage.categories.necessary, 'cookie-notification-acknowledged', 'yes', 365)

  recentlyAcknowledged.value = true
}
</script>

<template>
  <div class="mx-auto max-w-7xl px-5 lg:px-8 relative">
    <div v-if="!alreadyAcknowledged && !recentlyAcknowledged">
      <div class="py-6">
        <div
          role="heading"
          class="hidden"
        >
          Information
        </div>
        <div class="text-lg">
          <p class="">We use some essential cookies to make this service work.</p>
          <p class="last:mb-0">
            We’d like to set additional cookies so we can remember your settings, understand how people use the service
            and make improvements.
          </p>
        </div>
        <div
          class="mt-8 text-center *:mb-4 *:w-full md:inline-flex md:flex-wrap md:items-center md:*:mr-4 md:*:mt-0 *:md:w-auto"
        >
          <PrimaryButton
            type="button"
            @click="onAcceptAll"
          >
            Accept additional cookies
          </PrimaryButton>
          <PrimaryButton
            type="button"
            @click="onAcceptEssential"
          >
            Reject additional cookies
          </PrimaryButton>
          <AppLink
            :href="url"
            class="text-center font-normal"
          >
            View cookies
          </AppLink>
        </div>
      </div>
    </div>

    <div
      v-if="recentlyAcknowledged"
      ref="confirmationMessageElement"
      class="flex bg-state-positive p-2"
      role="alert"
      tabindex="-1"
    >
      <div class="container">
        <p
          class=""
          margin-class="mb-0"
        >
          Your cookie preferences have been saved. You can
          <AppLink
            :href="'/cookie-policy'"
            class="font-normal"
          >
            view cookie policy
          </AppLink>
          at any time.
        </p>
      </div>
    </div>
  </div>
</template>
